.divSobreNos {
    display: flex;
    flex-direction: column;
}

.h1sobreNos {
    font-size: 120px;
    font-weight: 800;
    background: -webkit-linear-gradient(45deg, #85009f, #ff06b4, #ff9900 50%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 25px;
    margin-bottom: 10px;
    margin-top: 0;
    margin-left: 80px;
}

.pDiv {
    font-size: 20px;
    color: white;
    font-weight: 200;
    width: 100%;
    padding-right: 70px;
    text-align: justify;
    text-indent: 3em;
}

#logoDark {
    width: 300px;
    border-radius: 15%;
    margin-top: -100px;
}

.divTextoLogo {
    display: flex;
    margin-left: 80px;
    margin-right: 80px;
}

.content {
    background-color: #231f20;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divVideo {
    background: linear-gradient(to right, #85009f, #ff06b4, #ff9900);
    padding: 5px;
    padding-bottom: 2px;
    margin-top: 20px;
    margin-bottom: 50px;
    border-radius: 0.5%;
}

.historia {
    background-color: white;
    padding: 80px;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-right: 25%;
    border-radius: 0px 0px 40px 0px;
}

.pNossaHistoria {
    text-indent: 3em;
}

.historiaIntegrantes {
    background: linear-gradient(0deg, rgba(71,2,149,1) 0%, rgba(242,17,180,1) 50%, rgba(255,184,0,1) 100%);
    padding-bottom: 100px;
}

#onda {
    margin-top: -220px;
}

#h1Historia {
    font-size: 100px;
    margin: 0;
    color: white;
    text-shadow: 2px 0 #231f20, -2px 0 #231f20, 0 2px #231f20, 0 -2px #231f20, 2px 2px #231f20, -2px -2px #231f20, 2px -2px #231f20, -2px 2px #231f20;
}

.imgGrupo {
    margin-top: 30px;
    width: 100%;
    border: groove 1em transparent;
    border-radius: 3em;
}

.imgsIntegrantes {
    width: 180px;
    border-radius: 50%;
    border: 6px solid white;
}

.sectionSobreNos {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.integrantes {
    display: flex;
    justify-content: space-evenly;
    margin: 40px;

}

.textSession {
    margin: 10px;
    margin-top: 5px;
    color: white;
    font-size: 20px;
    font-weight: 200;
}

.integranteNome {
    margin: 5px;
    color: white;
    font-size: 25px;
    margin-bottom: 0;
    margin-top: 10px;
}

#h1Integrantes {
    color: white;
    font-size: 50px;
    margin-bottom: 0;
    margin-top: 50px;
}

#linha {
    color: transparent;
    background-color: white;
    width: 500px;
    height: 2px;
}

.divTitulo {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 980px){
    .h1sobreNos{
        font-size: 50px;
        margin-left: 20px;
        
        
    }
    #logoDark{
        display: none;
    }

    .divTextoLogo {
        display: flex;
        margin-left: 0px;
        margin-right: 0px;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
        line-height: 1.2;
    }
    .pDiv{
        display: inline;
        width: 100%;
        padding-right:0px;

    }

    .divVideo{
        width:350px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .divVideo iframe {
        width: 100%;
    }

    .historiaIntegrantes{
        padding-bottom: 150px;
    }

    .historia{ 
        margin-right: 0%;
        padding: 20px;
        border-radius: 0px 0px 0px 0px;
    }

    #h1Historia {
        font-size: 40px;
    }

    .imgGrupo{
        width:90%;
    }

    #linha{
        width: 350px;
    }

    #h1Integrantes{
        font-size: 45px;
        border:0px;
    }

    .integrantes{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        
    }

    .sectionSobreNos{
        width: 120px; 
    }

    .integranteNome{
        text-align: center;
    }

    .imgsIntegrantes{
        width: 100px;
    }

    .onda{
        background-color: white;
    }

}