html{
    scroll-behavior: smooth;
}
#imgGrupo {
    width: 100%;
    mask-image: linear-gradient(to top, transparent 5%, black 100%);
}
#lampada{
    display: none;
}

@media (max-width: 980px){
    #imgGrupo {
        display: none;
    }
    
    #divLampada{
        display: flex;
        height: 800px;
        background-color:#2f2f2f;
        mask-image: linear-gradient(to top, transparent 5%, black 40%);
    }
    #lampada{
        display: flex;
        width: 100%;
    }

    #corLampada{
        background-color: rgba(242,17,180,1);
    }

    .MenuMobile{
        position:fixed;
        right:2px;
        z-index: 100;
        width: 60px;
        height: 60px;
    }
}

