.navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.divImgNavBar {
    background: linear-gradient(90deg, rgba(71,2,149,1) 0%, rgba(242,17,180,1) 50%, rgba(255,184,0,1) 100%);
    height: 59px;
}

.imgNavBar {
    width: 300px;
    margin: 10px;
    margin-left: 40px;
    margin-right: 40px;
    cursor: pointer;
}

.ulNavBar {
    margin-right: 50px;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    width: 700px;
}

.ulNavBar li {
  font-size: 20px;
  font-weight: 500;
}

.opçao:hover,
.opçao:focus {
    background: -webkit-linear-gradient(45deg, #85009f, #ff06b4, #ff9900 80%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


.opçao {
    text-decoration: none;
    color: black;
    position: relative;
    cursor: pointer;
    
}

.opçao::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, rgba(71,2,149,1) 0%, rgba(242,17,180,1) 50%, rgba(255,184,0,1) 100%);
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.5s; 
}

.opçao:hover::after {
    transform: scaleX(1);
}

@media (max-width: 980px){
    .navBar{
        display: none;
    }
}