.h1Produtos {
    font-size: 120px;
    font-weight: 800;
    background: -webkit-linear-gradient(45deg, #85009f, #ff06b4, #ff9900 85%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 25px;
    margin-bottom: 20px;
    margin-top: -26px;
    margin-left: 80px;
}

#logoBranca {
    width: 300px;
    margin-left: 120px;
    margin-top: -160px;
    border-radius: 50%;
}

.tituloProdutos {
    display: flex;
    margin-bottom: 10px;
}

.imgsProdutos {
    width: 40%;
    background: linear-gradient(to right, #85009f, #ff06b4, #ff9900);
    padding-top: 10px;
    padding-left: 0px;
    padding-bottom: 10px;
    padding-right: 10px;
    border: groove 0 transparent;
    border-radius: 0 4em 4em 0;
}

#imgDireita {
    padding-left: 10px;
    padding-right: 0;
    border-radius: 4em 0 0 4em;
}

.divProduto {
    display: flex;
}
.divProdutoEsquerda {
    display: flex;
}

.h2Produtos {
    font-size: 40px;
    margin-bottom: 0;
    color: #231f20;
}

.divText {
    padding: 50px;
}

.linhaProdutos {
    color: transparent;
    background-color: #231f20;
    height: 2px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 25px;
}

.textoProdutos {
    font-size: 18px;
    margin-top: 40px;
    text-align: justify;
}

@media (max-width: 980px){

    .h1Produtos{
        position:relative;
        font-size: 50px;
        margin-left: 20px;
        margin-top: 10px;
    }

    #logoBranca{
        position: absolute;
        width: 34%;
        margin-left: 66%;
        margin-top: -40px;
        z-index: -1;
        
    }

    .divProduto{
        flex-direction: column;
    }
    .h2Produtos{
        font-size: 30px;
    }
    .divProdutoEsquerda{
        flex-direction: column-reverse;
    }
    .linhaProdutos{
        background: linear-gradient(90deg, rgba(71,2,149,1) 0%, rgba(242,17,180,1) 50%, rgba(255,184,0,1) 100%);
    }
    .imgsProdutos{
        width: 100%;
        padding-top: 0px;
        padding-left: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
        border: groove 0 transparent;
        border-radius: 0 0 0 0;
        mask-image: linear-gradient(to top, transparent 5%, black 40%);
    }

    #imgDireita{
        padding-left: 0%;
        padding-right: 0;
        border-radius: 0em 0 0 0em;
    }

    .divText {
        padding: 20px;
    }
    
}