.h1Contatos {
    font-size: 120px;
    font-weight: 800;
    background: -webkit-linear-gradient(45deg, #85009f, #ff06b4, #ff9900 50%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 25px;
    margin-bottom: 10px;
    margin-top: -140px;
    margin-left: 80px;
}

.content {
    display: flex;
    width: 100%;
    height: 600px; /* provisório */
}

.faleConosco {
    background-color: #231f20;
    padding: 40px;
    padding-left: 80px;
    color: white;
    border: groove 1em transparent;
    border-radius: 0px 3em 3em 0px;
    width: 55%;
}

.redesSociais {
    width: 50%;
    background-color: white;
    margin-left: -10%;
    height: 240px;
    margin-top: 80px;
    border: groove 1em transparent;
    border-radius: 3em;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
}

.h2RedesSociais {
    color: #231f20;
    margin-bottom: 0px;
}

.sectionRedesSociais {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icons {
    font-size: 50px;
    padding: 30px;
    border-radius: 50%;
    color: white;
    margin-right: 30px;
    background: linear-gradient(45deg, rgba(71,2,149,1) 0%, rgba(242,17,180,1) 50%, rgba(255,184,0,1) 100%);
    transition: transform 0.3s;
    will-change: scale;
}

.icons:hover {
    transform: scale(1.05);
}

#divimg {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.divRedes {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.camuflagem {
    color: transparent;
    background-color: #231f20;
    height: 2px;
    width: 350px;
    margin-top: 10px;
    margin-bottom: 25px;
}

.contatosForm {
    display: flex;
    flex-direction: column;
}

.contatosLabel {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-bottom: 30px;
}

.contatosSpan {
    margin-bottom: 5px;
}

.contatosInput {
    padding: 10px;
    border-radius: 10px;
    color: black;
    font-family: 'Josefin Sans', sans-serif;
}

.contatosButton {
    padding: 10px;
    border: groove 0 transparent;
    border-radius: 4em;
    width: 70%;
    background: linear-gradient(to right, #85009f, #ff06b4, #ff9900);
    color: white;
    font-weight: bold;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 15px
}

@media (max-width: 980px){
    .divTitulo{
        padding-bottom: 170px;
}
    .h1Contatos{
        font-size: 50px;
        padding-left: 20px;
        margin-left: 0%;
        margin-top: 0px;
        padding-top: 100px;
        position: relative;
    }
    .content{
        flex-direction: column-reverse;
    }
    .avioes{
        width:90%;
        position:absolute;
    }
    .camuflagem{
        width: 100%;
    }
    .redesSociais{
        margin-left: 1%;
        padding: 0%;
        background: none;
        margin-bottom: -60px;
        position: relative;
    
    }
    .icons{
        padding: 20px;
        margin-right: 20px;
    }
    .faleConosco {
        border-radius: 0px 0em 0em 0px;
        width: auto;
    }
    .h2FaleConosco{
        text-align: center;
    }
    .contatosForm {
        align-items: center;
    }



}